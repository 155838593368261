import i18n from '@/i18n';

function initFormPartner() {
    return {
        corp_number: '',
        name: '',
        kana_name: '',
        email: '',
        phone_number: '',
        note: '',
        province: '',
        address_1: '',
        address_2: '',
        url: '',
        postal_code: '',
        keyword: '',
        tools: [initTools()],
        partner_master_order_scopes: [],
        mailbox_address: '',
        direct_address: '',
        local_delivery_pay: '',
        paid_by_recipient: '',

        configFieldMailbox: {
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.mailboxAddress'),
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldDirect: {
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.directAddress'),
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldLocalDeliveryPay: {
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.localDeliveryPayeePayable'),
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldPaidByRecipient: {
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.paidByRecipient'),
            valueSync: '',
            typesValidate: ['max255']
        },

        configFieldName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.name'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldPhoneNumber: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.phone'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max20', 'number-hyphens']
        },
        configFieldKanaName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.name_kana'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'katakana', 'max255']
        },
        configFieldPostalCode: {
            isNumber: true,
            maxlength: 10,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.postalCode'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required']
        },
        configFieldProvince: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.province'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldAddress1: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.address_1'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255'],
            placeholder: i18n.t('pldCommon.address_1')
        },
        configFieldAddress2: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.address_2'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255'],
            placeholder: i18n.t('pldCommon.address_2')
        },
        configFieldUrl: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.url'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255', 'url']
        },
        configFieldEmail: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.email'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255', 'email']
        },
        configFieldCorpNumber: {
            error: false
        }
    };
}

function initTools(data) {
    let objTool = {
        id: null,
        tool: '',
        configFieldTool: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.tool'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };

    if (!data) {
        objTool = {
            ...objTool
        };
    } else {
        objTool = {
            ...objTool,
            id: data?.id ?? null,
            tool: data?.tool ?? ''
        };
    }
    return objTool;
}

function initFormPartnerPIC() {
    return {
        name: '',
        kana_name: '',
        email: '',
        phone_number: '',
        password: '',
        department: '',
        mobile_number: '',
        account_id: null,
        chatwork_id: '',
        chatwork_token: '',

        configFieldName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.name'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldEmail: {
            autocomplete: 'new-email',
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.email'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255', 'email']
        },
        configFieldDepartment: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.department'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },

        configFieldKanaName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.personCharge'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['katakana', 'max255']
        },

        configFieldPhoneNumber: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.telephoneNumber'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max20', 'number-hyphens']
        },

        configFieldMobileNumber: {
            isNumber: true,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.cellphoneNumber'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max20']
        },

        configFieldPassword: {
            autocomplete: 'new-password',
            isNumber: false,
            isPassword: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pagePartner.password'),
            errorCustomMess: '',
            valueSync: '',
            type: 'password',
            typesValidate: ['required', 'max255', 'password']
        },

        configFieldAccountID: {
            isNumber: true,
            maxlength: 255,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.accountId'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldChatWorkID: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.chatworkId'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldChatWorkToken: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageEmployee.tokenUser'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };
}

export { initFormPartner, initTools, initFormPartnerPIC };
